.sidebar {
  position: fixed;
  right: 0px;
  top: 52px;
  width: 0px;
  // height: calc(100vh - 83px);
  height: 100%;
  overflow: hidden;
  z-index: 10;
  transition: 0.5s;
  background-color: white;
  touch-action: none;

  @media screen and (min-width: 1200px) {
    display: none;
  }
  &.opened {
    width: 100vw;
    transition: width 0.5s;
  }
  .navigation {
    margin-top: 20px;
    touch-action: none;

    .navigate {
      color: #656565;
      height: 30px;
      line-height: 30px;
      margin-left: 25px;
      width: fit-content;
      white-space: nowrap;
      touch-action: none;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .img-container {
        width: 30px;
        height: 30px;
        text-align: center;
        margin-right: 10px;
        position: relative;
        width: 30px;
        height: 30px;
        .color {
          transition: 0.3s all;
          opacity: 0;
        }
        svg {
          position: absolute;
          top: 0px;
          left: 0px;
        }
        &:hover,
        &.active {
          cursor: pointer;
          svg {
            &.color {
              transition: 0.3s all ease-in-out;
              opacity: 1;
            }
          }
        }
      }
      p {
        margin-left: 20px;
        margin: 0;
        line-height: 27px;
      }
    }
  }
}
