.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  &.small {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    width: 40px;
    height: 40px;
  }
  &.medium {
    width: 80px;
    height: 80px;
  }
  &.large {
    width: 100px;
    height: 100px;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}