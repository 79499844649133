.header-container {
  grid-row: 1 / 2;
  grid-column: 2 / -1;
  background-color: white;
  display: flex;
  z-index: 0;
  // flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 5px;
  @media screen and (max-width: 1200px) {
    height: 64px;
  }
  &.opened {
    touch-action: none;
    .logo {
      touch-action: none;
    }
    .burger-menu {
      touch-action: none;
    }
  }
  .language-select {
    margin-right: 50px;
    width: 70px;
    margin-top: 20px;
    .svg-container {
      display: flex;
      justify-content: space-between;
      .img {
        height: 40px;
        width: 40px;
      }
      p {
        margin: 0;
        padding-top: 10px;
        color: white;
      }
    }
    @media screen and (max-width: 992px) {
      display: none;
    }
  }
  .logo {
    margin-left: 25px;
    width: 80px;
    // float: left;
    display: inline-block;
    &:hover {
      cursor: pointer;
    }

    @media screen and (min-width: 992px) {
      margin-left: 45px;
    }
  }
  .sidebarAndLocation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .mobile-logo {
      display: none;
      margin-left: 10px;
      @media screen and (max-width: 1200px) {
        display: block;
      }
    }
    .clientName {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 20px;
      .name {
        margin-left: 10px;
        color: #343434;
        font-size: 1.2rem;
      }
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }

    .headerItems {
      display: flex;
      // flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-left: 30px;

      &.icons {
        @media screen and (max-width: 1200px) {
          display: none;
        }
      }

      .headerItem {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 30px;

        .iconHolder {
          position: relative;
          width: 24px;
          height: 24px;
          .color {
            transition: 0.3s all;
            opacity: 0;
          }
          svg {
            position: absolute;
            top: 0px;
            left: 0px;
          }
        }
        p {
          transition: 0.3s all ease-in-out;
          padding-left: 10px;
          font-size: 14px;
          color: #343434;
        }
        &:hover,
        &.active {
          cursor: pointer;
          .iconHolder {
            svg {
              &.color {
                transition: 0.3s all ease-in-out;
                opacity: 1;
              }
            }
          }
        }
        &:hover,
        &.active {
          p {
            opacity: 1;
            transform: translateX(0px);
            transition: 0.3s all ease-in-out;
            // transition-delay: 0.1s;
          }
        }
      }
    }

    .locationSelectDiv {
      @media screen and (min-width: 992px) {
        margin-right: 30px;
      }
    }
    .locationDiv {
      margin-right: 20px;
      position: relative;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      height: 20px;
      width: 170px;
      border-radius: 4px;
      border: 1px solid #2f2f2f;
      p {
        margin-right: 10px;
        font-size: 15px;
      }
      .headerArrow {
        width: 15px;
        height: 15px;
      }
      @media screen and (max-width: 450px) {
        margin-right: 0px;
      }
    }
    .locationDiv:hover .dropdownLocation {
      display: flex;
    }
    .dropdownLocation {
      display: none;
      position: absolute;
      width: 190px;
      min-height: 50px;
      max-height: 90px;
      overflow-y: auto;
      top: 37px;
      right: 0px;
      z-index: 1;
      background-color: white;
      padding: 10px 0px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      border-top: 1px solid #9c9c9c;
      border-radius: 0px 0px 4px 4px;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

      button {
        width: 170px;
        border: none;
        background-color: #f6f6f6;
        height: fit-content;
        transition: 0.3s all;
        border: 1px solid #e2e2e2;
        font-size: 12px;
        margin-bottom: 5px;
        border-radius: 4px;
        padding: 5px 10px;
        color: black;
        &:last-child {
          margin-bottom: 0px;
        }

        &:hover {
          cursor: pointer;
          transition: 0.3s all;
          background-color: rgb(240, 240, 240);
        }
        // &:first-child {
        //   border-radius: 10px 10px 0px 0px;
        // }
        // &:last-child {
        //   border-radius: 0px 0px 10px 10px;
        // }
      }
    }
  }
  .burger-menu {
    // display: block;
    // float: right;
    // padding-top: 20px;
    height: 35px;

    @media screen and (min-width: 1200px) {
      display: none;
    }
  }
}
