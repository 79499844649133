.logo {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
  border-right: 1px solid #f4f6f7;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1200px) {
    display: none;
    grid-row: unset;
    grid-column: unset;
  }
  .logoTag {
    &:hover {
      cursor: pointer;
    }
  }
}

.sidebar-desktop-container {
  grid-row: 2 / -1;
  grid-column: 1 / 2;
  border-right: 1px solid #f4f6f7;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  padding-bottom: 20px;

  @media screen and (max-width: 1200px) {
    display: none;
    grid-row: unset;
    grid-column: unset;
  }

  .navigation {
    margin-top: 20px;
    touch-action: none;

    .navigate {
      height: 30px;
      line-height: 30px;
      margin-left: 25px;
      width: fit-content;
      display: flex;
      justify-content: space-between;
      &:hover {
        cursor: pointer;
      }
      .img-container {
        width: 30px;
        height: 30px;
      }
      p {
        margin: 0;
        line-height: 27px;
      }
    }
  }
}
.svgHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  .iconHolder {
    position: relative;
    width: 24px;
    height: 24px;
    .color {
      transition: 0.3s all;
      opacity: 0;
    }
    svg {
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }
  &:hover,
  &.active {
    cursor: pointer;
    .iconHolder {
      svg {
        &.color {
          transition: 0.3s all ease-in-out;
          opacity: 1;
        }
      }
    }
  }
}
